{
  "en": {
    "change_requests": {
      "dialog": {
        "anchor": {
          "dropoff": "Requested Drop Off: ",
          "from_custom": "To Pick Up:",
          "from_school": "From School",
          "pickup": "Requested Pick Up: ",
          "same": "With Requested Anchor",
          "to_custom": "To Drop Off:",
          "to_school": "To School"
        },
        "button": {
          "cancel": "Cancel",
          "request": "Request Changes",
          "save": "Save"
        },
        "change_from": {
          "from_custom": "From Drop Off:",
          "from_school": "From Drop Off:",
          "to_custom": "From Pick Up:",
          "to_school": "From Pick Up:"
        },
        "change_to": {
          "from_custom": "To Drop Off:",
          "from_school": "To Drop Off:",
          "to_custom": "To Pick Up:",
          "to_school": "To Pick Up:"
        },
        "editor": {
          "change_request": {
            "from_custom": "Drop Off",
            "from_school": "Drop Off",
            "to_custom": "Pick Up",
            "to_school": "Pick Up"
          },
          "from_school": "From School / Drop Off",
          "to_school": "To School / Pick Up"
        },
        "label": {
          "from_custom": {
            "base": "A/B Outbound",
            "school": "From School",
            "school_stop": "To School"
          },
          "from_school": "From School",
          "to_custom": {
            "base": "A/B Inbound",
            "school": "To School",
            "school_stop": "From School"
          },
          "to_school": "To School"
        },
        "message": {
          "fail": "Change request update failed"
        },
        "resolve_request": "Resolve Request",
        "unenroll": "Change requested | Unenroll Student"
      },
      "errors": {
        "effective_date_in_past": "Effective date can't be earlier than now in school timezone (%{date})"
      },
      "status": {
        "option_approved": "Approve",
        "option_pending": "Pending",
        "option_rejected": "Reject",
        "resolve_type": "Choose Resolution Status"
      },
      "unenroll_form": {
        "approver_note": "Comment on resolution",
        "option_approved": "Unenroll student (archive)",
        "option_rejected": "Reject - keep enrolled",
        "resolve_type": "Choose Resolution Status"
      }
    },
    "common_config": {
      "address": {
        "form": {
          "address": "Address",
          "lat": "Address Latitude",
          "lng": "Address Longitude",
          "name": "Address Name"
        },
        "title": "Primary Address"
      }
    },
    "data_management": {
      "actions": {
        "cancel": "Cancel Changes",
        "delete": "Delete",
        "edit": "Edit",
        "remove": "Delete Row",
        "resolve_conflicts": "Resolve Route Conflict(s)",
        "save": "Save Changes"
      },
      "assigned_vehicles": {
        "another_vendor": "Another Vendor",
        "dates": {
          "til_end": "%{start_date} until the end of the route",
          "to_date": "%{start_date} - %{end_date}"
        },
        "delete": {
          "success": "Assignment deleted successfully"
        },
        "details": {
          "booking_id": "Booking ID",
          "capacity": "Capacity",
          "license_plate": "License Plate",
          "source": "Source",
          "tracking_device": "Tracking Device",
          "vehicle_type": "Type"
        },
        "errors": {
          "no_directions": "At least one direction is required",
          "no_prev_schedule": "​No permanent vendors and vehicles assigned. \"Add New Assignment\" and select \"until the end of route\" to denote permanent assignment",
          "past_date": "Please select future date",
          "start_date": "Start date can't be before route starts"
        },
        "filter": {
          "all": "All Assignments",
          "future": "Future Assignments",
          "today": "Today's Assignments"
        },
        "form": {
          "apply_to_pair": {
            "label": "Apply Changes to paired Run:",
            "negative": "No",
            "positive": "Yes"
          },
          "capacity": {
            "label": "Capacity"
          },
          "dates": {
            "label": "Effective Date for Assignment"
          },
          "device": {
            "label": "Tracking Device"
          },
          "notes": {
            "label": "Notes"
          },
          "one_day": {
            "label": "1-day Only"
          },
          "permanent": {
            "label": "Permanent (until the end of the route)"
          },
          "plate": {
            "label": "License Plate"
          },
          "source": {
            "label": "Source"
          },
          "submit": "Save Changes",
          "transportation_vendor": {
            "label": "Vendor"
          },
          "type": {
            "label": "Type"
          },
          "vehicle": {
            "label": "Vehicle"
          }
        },
        "headers": {
          "actions": "Actions",
          "dates": "Effective Dates",
          "inbound_pair": "Inbound Paired Route",
          "inbound_vehicle": "Inbound Vehicle",
          "notes": "Notes",
          "outbound_pair": "Outbound Paired Route",
          "outbound_vehicle": "Outbound Vehicle",
          "permanent": "Permanent assignment",
          "vendor": "Vendor"
        },
        "no_schedules": "No vendors and vehicles assigned.",
        "no_vehicle": "Unassigned",
        "sections": {
          "inbound": "Inbound",
          "outbound": "Outbound"
        },
        "submit": {
          "success": "Vehicles Assigned Successfully"
        },
        "title": "Assigned Vehicles",
        "tooltips": {
          "permanent": "Permanent Assignment",
          "today": "Today's Assignment"
        },
        "warnings": {
          "another_vendor": "Another vendor",
          "bookings_provider": "Vehicles for this vendor are assigned per trip (day-of). Check the Tracking view for vehicle assignment.",
          "paired_route": "Changes will automatically be applied to paired route: ",
          "unpair": "To unpair routes visit: ",
          "unpair_link": "Manage Paired Routes",
          "vehicle_type": "Vehicle does not match type for this route: %{vehicle_type}."
        }
      },
      "changelogs": {
        "breadcrumb": "All Changelogs",
        "sub": "Create Report"
      },
      "edit_stop_location": {
        "btn": "Edit Stop",
        "title": "Edit Stop"
      },
      "errors": {
        "empty": "At least one %{name} is required.",
        "fail": "%{name} changes failed.",
        "remove": "%{name} removing failed.",
        "unique": "%{name} already exists. This field should be unique."
      },
      "exports": {
        "file_link": "Export File",
        "run_export": "Run Export"
      },
      "import_conflicts": {
        "accept_all": "Accept All",
        "actions": "Actions",
        "breadcrumb": "All Route Imports",
        "conflict": "Conflict",
        "reject_all": "Reject All",
        "student_route": "Student, New Route",
        "submit": "Submit",
        "title": "Resolve Conflicts"
      },
      "imports": {
        "form": {
          "days": {
            "label": "On:"
          },
          "end_date": {
            "description": "(applies to new routes only)",
            "label": "End Date for new routes"
          },
          "end_on_last_day": {
            "label": "End on last day of school"
          },
          "school": {
            "empty": "Select a School",
            "label": "School"
          },
          "start_date": {
            "description": "(applies to new and existing routes)",
            "label": "Start Date for updates"
          },
          "submit": "Create Import"
        }
      },
      "new_route_import": {
        "btn": "New Routes Import",
        "title": "Import New Routes"
      },
      "new_stop_location": {
        "btn": "New Stop Location",
        "title": "New Stop Location"
      },
      "new_student_import": {
        "btn": "New Students Import",
        "title": "Import New Student Roster"
      },
      "new_text_blast": {
        "btn": "New Text Blast",
        "title": "Send New Text Blast"
      },
      "paired_routes": {
        "directions": {
          "from_school": "From School",
          "to_school": "To School",
          "to_school_from_school": "To and From School"
        },
        "errors": {
          "directions": "This route's %{direction} has no stops."
        },
        "headers": {
          "actions": "Actions",
          "direction": "Direction",
          "notes": "Notes",
          "status": "Status",
          "tier_1_route": "Tier 1 Route",
          "tier_1_times": "Tier 1 Arrival Times",
          "tier_1_vehicles": "Tier 1 Vehicles",
          "tier_2_route": "Tier 2 Route",
          "tier_2_times": "Tier 2 Arrival Times",
          "tier_2_vehicles": "Tier 2 Vehicles",
          "vendor": "Vendor"
        },
        "info": {
          "tier": "Tier %{tier}:"
        },
        "messages": {
          "button": {
            "cancel": "Cancel"
          },
          "times_mismatch": {
            "button": "Save Pairing",
            "message": "Tier 1's times occur AFTER Tier 2 times. Review the pairing to ensure this is correct.",
            "title": "Are you sure you want to save this pairing?"
          },
          "vehicles_mismatch": {
            "button": "Change Tier 2 Vehicles",
            "message": "​To save this pairing, Tier 2 vehicles will automatically be updated to match the Tier 1 vehicles.",
            "title": "Vehicles for the pairing do not match"
          }
        },
        "notifications": {
          "vehicles_updated": {
            "from_school": "From School: %{vehicle}",
            "message": "​%{route} permanent vehicle assignment has been changed.",
            "to_school": "To School: %{vehicle}",
            "unassigned": "Unassigned"
          }
        },
        "times": {
          "dropoff": "Last Drop Planned: %{time}",
          "pickup": "First Pick Up Planned: %{time}"
        },
        "title": "Manage Paired Routes",
        "vehicles": {
          "inbound": "To School: %{vehicle}",
          "outbound": "From School: %{vehicle}"
        }
      },
      "routes": {
        "breadcrumb": "All Routes"
      },
      "stop_location": {
        "labels": {
          "address": "Address",
          "created_at": "Created at",
          "name": "Name",
          "school": "School",
          "updated_at": "Updated at"
        },
        "title": {
          "details": "Stop Location Details"
        },
        "update": "Update Stop Location"
      },
      "stop_locations": {
        "breadcrumb": "All Stops",
        "form": {
          "address": {
            "empty": "New Address",
            "label": "Address"
          },
          "lat": {
            "empty": "Address Lat",
            "label": "Lat"
          },
          "lng": {
            "empty": "Address Lng",
            "label": "Lng"
          },
          "name": {
            "empty": "New stop name",
            "label": "Stop Name"
          },
          "school": {
            "empty": "Select a School",
            "label": "School"
          },
          "submit": "Create Stop Location"
        }
      },
      "students": {
        "breadcrumb": "All Students"
      },
      "text_blast": {
        "labels": {
          "created_at": "Created at",
          "details": "Details",
          "process_at": "Process at",
          "school": "School",
          "status": "Status",
          "user": "Initiated by"
        },
        "title": {
          "details": "Text Blast Details:",
          "errors": "Errors",
          "main": "Text Blast #%{id}",
          "sent": "Sent to"
        }
      },
      "text_blasts": {
        "breadcrumb": "All Text Blasts",
        "form": {
          "process_at": {
            "label": "Process at (%{tz})"
          },
          "school": {
            "empty": "Select a School",
            "label": "School"
          },
          "submit": "Create Text Blast"
        }
      },
      "title": "Data Management"
    },
    "district_config": {
      "district": {
        "form": {
          "code": {
            "label": "Code"
          },
          "hotline_email": {
            "label": "Hotline Email"
          },
          "icabbi_account_ref": {
            "label": "ICABBI Account Ref"
          },
          "name": {
            "label": "Name"
          },
          "schools": {
            "label": "Schools"
          },
          "slug": {
            "label": "Slug"
          }
        },
        "labels": {
          "code": "Code",
          "created_at": "Created at",
          "hotline_email": "Hotline Email",
          "icabbi_account_ref": "ICABBI Account Ref",
          "name": "Name",
          "schools": "Schools",
          "slug": "Slug",
          "time_zone_abbr": "Time Zone",
          "twilio_number": "Twilio Number"
        },
        "title": {
          "details": "District Details",
          "phone_numbers": "Phone Numbers"
        }
      },
      "districts": {
        "breadcrumb": "All Districts"
      },
      "edit_district": {
        "btn": "Edit District",
        "title": "Edit District"
      },
      "new_district": {
        "btn": "New District",
        "title": "New District"
      },
      "title": "District Configuration"
    },
    "report": {
      "changelogs": {
        "all_vendors": "All Vendors",
        "days_off": "Include Days Off",
        "email": {
          "all_days": "All Days",
          "all_riders": "All Riders",
          "all_routes": "All Routes",
          "cancels": "Ride - Cancelled / Not Riding",
          "dates": "Effective from %{date} to %{date_to}",
          "day_deltas": {
            "change": "Time Change",
            "early_release": "EARLY RELEASE",
            "late_start": "LATE START",
            "moved": {
              "early": "earlier",
              "late": "later"
            },
            "time_change": {
              "hours": "All stops moved %{hours} hours %{earlier_later}",
              "minutes": "All stops moved %{hours} hours %{minutes} minutes %{earlier_later}",
              "to_time_early": "Routes start earlier, at %{time}",
              "to_time_late": "All stops moved later. Routes arrive at %{time}"
            }
          },
          "days_off": {
            "no_run": "Routes do not run"
          },
          "details_attached": "See route sheet for details",
          "directions": {
            "from_custom": "From Activity",
            "from_custom_only": "From Activity ONLY",
            "from_school": "From School",
            "from_school_only": "From School ONLY",
            "to_custom": "To Activity",
            "to_custom_only": "To Activity ONLY",
            "to_school": "To School",
            "to_school_only": "To School ONLY"
          },
          "except_routes": "Except:",
          "is_new": "New Route",
          "name_updates": "Update student name",
          "new_stop": "New Stop - Added",
          "no_changes": "NO CHANGES",
          "no_school": "NO SCHOOL",
          "one_day_changes": "One-Day Change",
          "one_offs": "Rider - Added",
          "only_date": "%{day}, %{date} ONLY",
          "permanent_add": "Rider - Added",
          "permanent_remove": "Rider - Removed",
          "phone_updates": "Update contact phone number",
          "program_updates": "Update student program",
          "removed_stop": "Stop - Removed",
          "subject": "Route Updates (Effective %{date} to %{date_to})",
          "subtitles": {
            "no_changes": "Routes with No Changes",
            "route_changes": "Route Changes",
            "schedule_changes": "Schedule Changes (No School / Early Release / Late Starts)"
          },
          "time_changed": "Route Time Change",
          "title": "Summary of Route Updates for %{vendor_or_school}"
        },
        "permanent_add": {
          "message": "%{route}, (please make %{sessions} recurring, %{days})",
          "title": "Permanent add:"
        },
        "permanent_remove": {
          "message": "%{route}, (please make %{sessions} recurring, %{days})",
          "title": "Permanent remove:"
        },
        "stops_changes": {
          "add_stop_student": "A new stop, %{stop}, was added",
          "remove_stop_student": "Stop at %{stop} was removed",
          "title": "Stops changes:"
        },
        "student_stop_changes": {
          "message": "%{route}, (please make %{sessions} recurring, %{days})",
          "title": "Student stop change:"
        },
        "vendor": "Transportation Vendor"
      },
      "curbside": {
        "aside": {
          "csv": "Using <b>All</b> sessions with CSV will generate a separate file for each session.",
          "pdf": "Using <b>All</b> sessions with PDF files will generate a single consolidated file."
        },
        "columns": {
          "anchor_time": {
            "from_school": "PickUp",
            "to_school": "DropOff"
          }
        },
        "time": {
          "all": "Include Pickup Up / Drop Off times",
          "from_school": "Include Pick Up Time",
          "to_school": "Include Drop Off Time"
        }
      },
      "errors": {
        "dates": {
          "date_range": "Date range is invalid. Only %{days} days allowed"
        },
        "generation_for": "Report generation failed for %{schools}: %{error}",
        "routes": {
          "no_with_changes": "No routes with changes found"
        },
        "transportation_vendor": {
          "one": "No transportation vendor for route %{route}",
          "other": "No transportation vendor for routes %{route}"
        }
      },
      "form": {
        "btn": {
          "create": "Create Report",
          "preview": "Preview Report",
          "save_pdf": "Save as pdf",
          "view": "View Reports"
        },
        "hint": {
          "changelogs_date_range": "Dates for changelogs report refer to effective dates of the changes",
          "route_sheets_date_range": "Route cycles are typically Wednesday through Tuesday"
        },
        "label": {
          "date": "Date:",
          "date_from": "From",
          "date_range": "Effective Date Range",
          "date_range_from": "From Date:",
          "date_to": "To",
          "outputs": "Report Outputs",
          "route_options": {
            "all": "All Routes",
            "with_changes": "All Routes with Changes"
          },
          "routes": "Select Specific Routes",
          "routes_for": "Routes for",
          "routes_selection": "Routes to include",
          "school": "Schools:",
          "sheets_date_range": "7-Day Date Range"
        }
      },
      "preview": {
        "avery": "Dismissal Labels - Avery 95945 Preview",
        "emergency": "If Found Call"
      },
      "status": {
        "download": "download it",
        "generating": {
          "multiple": "Generating reports.",
          "single": "Generating report."
        },
        "ready": "Your report is ready:",
        "route_sheets": {
          "email": "Your report is ready, you will receive an email shortly"
        },
        "view": "view all reports"
      },
      "types": {
        "adm": "ADM",
        "all_trips": "All Trips",
        "avery": "Dismissal Labels - Avery 95945",
        "changelogs": "Changelogs",
        "curbside": "Curbside",
        "district_curbside": "District Curbside",
        "historical_trips": "Historical Trips",
        "route_sheets": "Route Sheets and change summary",
        "school_events": "School Events",
        "students": "Students",
        "weekly_curbside": "Weekly Curbside"
      }
    },
    "route": {
      "create": {
        "message": {
          "fail": "Route creation failed",
          "fail_trip": "Trip creation failed",
          "success": "%{name} was added successfully",
          "success_trip": "Trip was added successfully"
        }
      },
      "create_return": {
        "message": {
          "fail": "Return route creation failed",
          "success": "Return route was created successfully"
        }
      },
      "direction": {
        "custom": "Other",
        "from_custom": "B/A",
        "from_school": "From School",
        "to_custom": "A/B",
        "to_school": "To School"
      },
      "edit": {
        "archived": "The route has been archived.",
        "confirm_archive": "Please confirm that the route should be archived",
        "end_date": "End Date",
        "name": "Route Name",
        "routable_type": "Association Type",
        "start_date": "Start Date",
        "update": "Update Route"
      },
      "ended_notification": {
        "body": "Your trip %{type} %{school_name} has ended.",
        "title": "Trip Ended"
      },
      "errors": {
        "anchor": "can't be blank",
        "dates": {
          "end": "End date can not be greater than route end",
          "invalid": "Invalid date/time format",
          "start": "Start date can not be less than route start",
          "start_date_required": "Start date is required",
          "start_end": "End date can not be earlier than start date"
        },
        "days_of_week": "Chosen weekdays can't be blank",
        "restrict_non_riding": "Can't modify non-riding route",
        "route_not_found": "Unknown Route",
        "schedule": {
          "not_running": "Route isn't running on this date"
        },
        "school_icabbi_account_missing": "School icabbi account is missing",
        "stops": "can't be blank",
        "trip_types": "Wrong directions",
        "vendor_missing": "Transportation vendor missing",
        "wrong_vehicle_type": "Wrong vehicle type"
      },
      "push_to_avl": {
        "message": {
          "success": "Route will be pushed to AVL shortly"
        }
      },
      "riders_type": {
        "delivery": "Delivery",
        "students": "Students"
      },
      "route_type": {
        "activity": "Activity",
        "custom": "Other",
        "regular": "Primary"
      },
      "scope": {
        "active": "Active",
        "archived": "Archived",
        "current": "Current",
        "today": "Today"
      },
      "upcoming_notification": {
        "body": "Your trip %{type} %{school_name} has started.",
        "title": "Trip Started"
      }
    },
    "school_config": {
      "comms_group": {
        "labels": {
          "avl_notifications": "Avl Notifications",
          "created_at": "Created at",
          "district": "District",
          "name": "Name",
          "reply_type": "Reply Type",
          "route": "Route",
          "route_type": "Route Type",
          "school": "School",
          "twilio_number": "Twilio Number",
          "updated_at": "Updated at"
        },
        "title": {
          "contacts": "Contacts",
          "details": "Communication Group Details",
          "phone_numbers": "Twilio Phone Number"
        },
        "update": "Update Communication Group"
      },
      "comms_groups": {
        "breadcrumb": "All Groups",
        "form": {
          "avl_notifications": {
            "label": "Use this communication group for AVL notifications"
          },
          "district": {
            "empty": "Select a District",
            "label": "District"
          },
          "name": {
            "empty": "New group name",
            "label": "Group Name"
          },
          "reply_type": {
            "label": "Reply Type"
          },
          "save": "Save Group",
          "school": {
            "empty": "Select a School",
            "label": "School"
          },
          "submit": "Create Group"
        }
      },
      "day_delta": {
        "actions": {
          "collapse": "hide all occurrences",
          "collapse_all": "Collapse all occurrences",
          "expand": "show all occurrences",
          "expand_all": "Expand all occurrences",
          "filter": {
            "select": {
              "all": "All times",
              "future": "Future times ONLY"
            },
            "show_times": "Display 'school times' excluded from schedule adjustments"
          }
        },
        "adjustment_type": {
          "early_release": "Early Release",
          "late_start": "Late Start"
        },
        "arrival": {
          "new": "New Arrival: %{time}",
          "original": "Original Arrival: %{time}"
        },
        "dismissal_time": {
          "delta": "Delta",
          "new": "New Dismissal Time",
          "previous": "Previous Pickup Time",
          "time": "Early Dismissal Time",
          "title": "Early Dismissal Times"
        },
        "form": {
          "adjustment_type": {
            "label": "Type of Adjustment"
          },
          "comment": {
            "label": "Comment"
          },
          "days": {
            "label": "Date of Time Change"
          },
          "delta": {
            "label": "New Time/Delta"
          },
          "name": {
            "label": "Name and Time Affected"
          },
          "occurrence": {
            "label": "Occurrence"
          },
          "previous_pickup": {
            "label": "Previous Pickup"
          },
          "start_date": {
            "label": "Date"
          },
          "time": {
            "label": "New Dismissal Time"
          },
          "view_times": "view times"
        },
        "occurrence": {
          "past": "Already Occurred",
          "schedule": "Weekly on %{day}",
          "title": {
            "one": "1 Future occurrence",
            "other": "%{count} Future occurrences",
            "zero": "No Future occurrences"
          }
        },
        "popup": {
          "actions": {
            "create": "Save and Add",
            "delete": "Delete"
          },
          "form": {
            "adjustment_type": {
              "label": "Type of Adjustment"
            },
            "comment": {
              "label": "Comments"
            },
            "date": {
              "label": "Date of Time Change"
            },
            "delta_type": {
              "delta": "A delta",
              "early_release": "Before the original arrival time",
              "hours": "Hours",
              "label": "Adjust Schedule Based On",
              "late_start": "After the original arrival time",
              "minutes": "Minutes",
              "time": "New Arrival Time"
            },
            "occurrence": {
              "past": "Already Occurred"
            },
            "off_type": {
              "interval": "Weekly on %{day}",
              "single_day": "Does not repeat"
            },
            "school_delta_times": {
              "delta": "Delta",
              "disabled": "To enable this school time, the New Arrival Time must be more than 2 hours in future.",
              "label": "Choose which School Times to apply to",
              "name": "Name",
              "new_time": "New Arrival Time",
              "original_time": "Original Arrival Time",
              "wait_time": "Wait Time"
            }
          },
          "title": {
            "edit": "Edit Schedule Adjustment",
            "new": "Adjust Schedule"
          }
        }
      },
      "day_off": {
        "form": {
          "comment": {
            "label": "Comment"
          },
          "end_date": {
            "label": "End Date"
          },
          "off_type": {
            "label": "Type"
          },
          "start_date": {
            "label": "Start Date"
          }
        }
      },
      "dismissal_group": {
        "labels": {
          "created_at": "Created at",
          "name": "Name",
          "school": "School",
          "updated_at": "Updated at"
        },
        "title": {
          "details": "Dismissal Group Details"
        },
        "update": "Update Dismissal Group"
      },
      "dismissal_groups": {
        "breadcrumb": "All Groups",
        "form": {
          "name": {
            "empty": "New group name",
            "label": "Group Name"
          },
          "save": "Save Group",
          "school": {
            "empty": "Select a School",
            "label": "School"
          },
          "submit": "Create Group"
        }
      },
      "edit_comms_group": {
        "btn": "Edit Group",
        "title": "Edit Group"
      },
      "edit_dismissal_group": {
        "btn": "Edit Group",
        "title": "Edit Group"
      },
      "edit_school": {
        "btn": "Edit School",
        "title": "Edit School"
      },
      "edit_staff": {
        "btn": "Edit School Staff",
        "title": "Edit School Staff"
      },
      "group_contact": {
        "form": {
          "contact": {
            "label": "Contact"
          }
        }
      },
      "new_comms_group": {
        "btn": "New Communication Group",
        "title": "New Communication Group"
      },
      "new_day_delta": {
        "btn": "Add New Time"
      },
      "new_day_off": {
        "btn": "New Day Off"
      },
      "new_dismissal_group": {
        "btn": "New Dismissal Group",
        "title": "New Dismissal Group"
      },
      "new_group_contact": {
        "btn": "New Contact"
      },
      "new_school": {
        "btn": "New School",
        "title": "New School"
      },
      "new_school_time": {
        "btn": "New School Time"
      },
      "new_staff": {
        "btn": "New School Staff",
        "title": "New School Staff"
      },
      "school": {
        "form": {
          "all_admins_access": {
            "label": "All Admin Access"
          },
          "code": {
            "label": "Code"
          },
          "emergency_contact": {
            "label": "Emergency Contact Number"
          },
          "first_day": {
            "label": "First Day"
          },
          "hotline_email": {
            "label": "Hotline Email"
          },
          "icabbi_account_ref": {
            "label": "ICABBI Account Ref"
          },
          "last_day": {
            "label": "Last Day"
          },
          "name": {
            "label": "Name"
          },
          "school_days_count": {
            "label": "School Days Count"
          },
          "slack_integration": {
            "label": "Slack Integration Enabled"
          },
          "slug": {
            "label": "Slug"
          }
        },
        "labels": {
          "code": "Code",
          "created_at": "Created at",
          "district": "District",
          "emergency_contact": "Emergency Contact Number",
          "first_day": "First Day",
          "grades_served": "Grades Served",
          "hotline_email": "Hotline Email",
          "icabbi_account_ref": "ICABBI Account Ref",
          "last_day": "Last Day",
          "name": "Name",
          "slack_integration_enabled": "Slack Integration Enabled?",
          "slug": "Slug",
          "time_zone_abbr": "Time Zone",
          "twilio_number": "Twilio Number"
        },
        "title": {
          "addresses": "Addresses",
          "communication_groups": "Communication Groups",
          "days_delta": "Adjust Schedule (Late Starts, Early Release)",
          "days_off": "Days Off",
          "details": "School Details",
          "phone_numbers": "Phone Numbers",
          "school_times": "Pick-up, Drop-off Times",
          "users": "Staff"
        }
      },
      "school_time": {
        "form": {
          "departure_time": {
            "label": "Departure Time",
            "sublabel": "(Arrival + Wait)"
          },
          "name": {
            "label": "Name"
          },
          "primary": {
            "label": "Primary"
          },
          "time": {
            "label": "Arrival Time"
          },
          "time_type": {
            "label": "Type"
          },
          "wait_time": {
            "label": "Wait Time",
            "sublabel": "(Minutes)"
          }
        },
        "messages": {
          "new": {
            "button": {
              "cancel": "Close",
              "ok": "Go to Late Start / Early Release Table"
            },
            "text": "There may be existing Late Starts or Early Releases that you may want to add this new school time to.",
            "title": "Late Start or Early Release?"
          },
          "update": {
            "button": {
              "cancel": "No, do not save",
              "ok": "Yes, save changes"
            },
            "cta": "Do you want to continue?",
            "delta_times": "Existing Late Starts or Early Releases using this time will automatically be updated to reflect this new time change.",
            "routes": "Existing routes using this time will automatically be updated to reflect this new time change:",
            "title": "Confirm Time Change"
          }
        }
      },
      "schools": {
        "breadcrumb": "All Schools"
      },
      "staff": {
        "labels": {
          "communication_access": "COMMS Access",
          "created_at": "Created at",
          "email": "Email",
          "first_name": "First Name",
          "last_name": "Last Name",
          "no_access": "No Access",
          "phone_number": "Phone",
          "read_only": "Read Only TOMS Access",
          "role": "Role",
          "school": "School(s)",
          "staff_role": "Staff Role",
          "system_user": "Full TOMS Access",
          "updated_at": "Updated at"
        },
        "title": {
          "communication_groups": "Communication Groups",
          "details": "Staff Member Details",
          "phone_numbers": "Phone Numbers"
        },
        "update": "Update Staff Member"
      },
      "staffs": {
        "breadcrumb": "All School Staff",
        "form": {
          "communication_access": {
            "label": "COMMS Access"
          },
          "email": {
            "empty": "Email",
            "label": "Email"
          },
          "first_name": {
            "empty": "First Name",
            "label": "First Name"
          },
          "last_name": {
            "empty": "Last Name",
            "label": "Last Name"
          },
          "no_access": {
            "label": "No Access"
          },
          "read_only": {
            "label": "Read only TOMS access"
          },
          "save": "Save School Staff",
          "school": {
            "empty": "Select a School",
            "label": "School(s)"
          },
          "sms_notifications": {
            "label": "SMS reminder for unread school messages"
          },
          "staff_role": {
            "empty": "Staff Role",
            "label": "Staff Role"
          },
          "submit": "Create School Staff",
          "system_user": {
            "label": "Full TOMS Access"
          }
        }
      },
      "title": "School Configuration"
    },
    "sessions": {
      "buttons": {
        "admin_home": "Admin Home",
        "send_password": "Send me reset password instructions",
        "sign_out": "Sign Out",
        "update_password": "Change my password"
      },
      "form": {
        "email": {
          "empty": "Email",
          "label": "Email"
        },
        "new_password": {
          "empty": "New password",
          "label": "New password"
        },
        "password": {
          "empty": "Password",
          "label": "Password"
        },
        "password_confirmation": {
          "empty": "Confirm new password",
          "label": "Confirm new password"
        },
        "remember_me": {
          "label": "Remember me"
        },
        "submit": "Log In"
      },
      "links": {
        "new_password": "Forgot your password?",
        "sign_in": "Log in"
      },
      "login": {
        "success": "Successfully logged in"
      },
      "password_request": {
        "success": "You will receive an email with password reset instructions in a few minutes."
      },
      "password_reset": {
        "success": "Your password has been changed successfully."
      }
    },
    "settings_config": {
      "edit_program": {
        "btn": "Edit Program",
        "title": "Edit Program"
      },
      "edit_user": {
        "btn": "Edit User",
        "title": "Edit User"
      },
      "new_program": {
        "btn": "New Program",
        "title": "New Program"
      },
      "new_user": {
        "btn": "New User",
        "title": "New User"
      },
      "program": {
        "labels": {
          "abbreviation": "Abbreviation",
          "created_at": "Created at",
          "name": "Name"
        },
        "title": {
          "details": "Program Details"
        }
      },
      "programs": {
        "breadcrumb": "All Programs",
        "form": {
          "abbreviation": {
            "empty": "Abbreviation",
            "label": "Abbreviation"
          },
          "name": {
            "empty": "Name",
            "label": "Name"
          },
          "save": "Save Program",
          "submit": "Create Program"
        }
      },
      "title": "Settings",
      "user": {
        "labels": {
          "created_at": "Created at",
          "email": "Email",
          "first_name": "First Name",
          "last_name": "Last Name",
          "name": "Name",
          "phone_number": "Phone",
          "schools": "Schools",
          "updated_at": "Updated at"
        },
        "title": {
          "communication_groups": "Communication Groups",
          "details": "User Details",
          "phone_numbers": "Phone Numbers",
          "schools": "Schools"
        }
      },
      "users": {
        "breadcrumb": "All Users",
        "form": {
          "email": {
            "empty": "Email",
            "label": "Email"
          },
          "first_name": {
            "empty": "First Name",
            "label": "First Name"
          },
          "last_name": {
            "empty": "Last Name",
            "label": "Last Name"
          },
          "restricted_access": {
            "label": "Restricted Access"
          },
          "save": "Save User",
          "schools": {
            "label": "Schools"
          },
          "sms_notifications": {
            "label": "SMS reminder for unread school messages"
          },
          "submit": "Create User"
        }
      }
    },
    "stop": {
      "add_student": {
        "message": {
          "fail": "Adding student failed"
        }
      },
      "attributes": {
        "stop_type": {
          "home": "home",
          "school": "school"
        }
      },
      "create": {
        "message": {
          "fail": "Stop creation failed"
        }
      },
      "errors": {
        "anchor": {
          "after": "should be after direct trip dropoff time (%{time})",
          "before": "should be before return trip pickup time (%{time})",
          "multiple_schools": "should be only one school stop per school (%{school}) for the same session",
          "not_found": {
            "custom": "No anchor (last stop) for this route",
            "regular": "School stop for the student %{student} wasn't found"
          },
          "not_found_for": {
            "custom": "No anchor (last stop) for %{student}, %{trip_type}, %{day_of_week} at %{route} (%{route_id})",
            "regular": "School stop at %{route} (%{route_id}) for %{student}, %{trip_type}, %{day_of_week} wasn't found"
          }
        },
        "not_enough": "should have at least one pickup/dropoff",
        "not_found": "Stop not found",
        "not_found_period": "Route isn't running on this period",
        "not_found_school": "School not found",
        "time": {
          "after": "should be after pickup time (%{time})",
          "before": "should be before dropoff time (%{time})",
          "duplicate": "There is already existing stop with the same location and time (%{time})",
          "pickup_dropoff": "pickup dropoff times should be one (%{pickup}) before another (%{dropoff})"
        },
        "too_many": "should have only one pickup/dropoff",
        "week_day": {
          "date": "Date is required",
          "trip_type": "Session is required"
        }
      },
      "rollback": {
        "message": {
          "fail": "Rollback failed"
        }
      },
      "schedule": {
        "message": {
          "fail": "Schedule changes update failed",
          "success": "Schedule was updated successfully"
        }
      }
    },
    "student": {
      "accommodation": {
        "accommodation_empty": "An Accommodation",
        "accommodation_none": "None",
        "accommodation_note": "Rider Note",
        "accommodation_type": "Accommodation",
        "accommodation_types": {
          "aide": "Aide",
          "curb_to_curb": "Curb-to-Curb",
          "small_vehicle": "Small Vehicle",
          "solo": "Solo",
          "wheelchair": "Wheelchair"
        },
        "remaining": "%{count} Characters remaining",
        "title": "Accommodations and Rider Note"
      },
      "errors": {
        "route_event_activity": "Can't add activity event for student %{student} as there is already another event for that date",
        "route_event_cancel_started": "Can't add cancel or student %{student} for the route that already started",
        "route_event_future": "Can't add no show or one-off events for student %{student} for future dates"
      },
      "event": {
        "activity": "Activity",
        "cancel": "Cancel",
        "cancel_pass": "Cancel Pass",
        "change": "Change",
        "change_req": "CH. REQ",
        "day_off": "Day Off",
        "icon": {
          "activity": {
            "text": "A",
            "tooltip": "Ride is to an Activity"
          },
          "cancel": {
            "text": "X",
            "tooltip": "Ride cancelled"
          },
          "new": {
            "text": "N",
            "tooltip": "New permanent ride change starting today."
          },
          "no_show": {
            "text": "NO",
            "tooltip": "Ride marked as No Show"
          },
          "one_off": {
            "text": "$",
            "tooltip": "One-off ride change."
          },
          "pass": {
            "text": "P",
            "tooltip": "Ride Pass. One-day ride change."
          }
        },
        "label": {
          "ok": "Choose...",
          "undo": "Undo %{event}"
        },
        "late_activity": "Late Activity",
        "late_cancel": "Late Cancel",
        "late_cancel_pass": "Late Cancel Pass",
        "late_no_show": "No Show",
        "late_one_off": "1-Off",
        "late_student_add": "Ride Pass",
        "no_show": "No Show",
        "ok": "OK",
        "one_off": "1-Off",
        "reason": {
          "activity": "Assigned to: ",
          "cancel": "Assigned to: ",
          "change": "Changed to: ",
          "one_off": "Changed to: ",
          "requested": "Change Requested "
        },
        "remove": "Remove",
        "revert": {
          "activity": "undo activity",
          "cancel": "undo cancel",
          "cancel_pass": "cancel this pass",
          "late_activity": "undo late activity",
          "late_cancel": "undo late cancel",
          "late_cancel_pass": "cancel this late pass",
          "no_show": "undo no show"
        },
        "status": {
          "activity": "activity",
          "another_route": "different stop",
          "carpool": "carpool",
          "distance": "distance",
          "one_off": "one-off ride",
          "ppu": "PPU",
          "primary_route": "primary route",
          "transit": "transit",
          "walk": "walk"
        },
        "student_add": "Ride Pass",
        "tooltip": {
          "late_cancel": "Ride is less than 2 hours away. To make changes to this ride, contact 4mativ."
        }
      },
      "reenroll": {
        "failure": "Failed to re-enroll student %{student}",
        "success": "Successfully re-enrolled student %{student}"
      },
      "rider_type": {
        "aide": "Aide",
        "student": "Student"
      },
      "route_event": {
        "added": "A %{event} has been added for %{student} %{date}."
      },
      "unenroll": {
        "failure": "Failed to unenroll student %{student}",
        "success": "Successfully unenrolled student %{student}"
      }
    },
    "students": {
      "active": "Active",
      "create_report": "Create Report",
      "form": {
        "accommodation": "Accommodations and Rider Note",
        "add_note": "Add a note",
        "add_transportation": "Add Transportation",
        "addresses": "Addresses",
        "details": "Details",
        "effective_date": "Effective Date",
        "phone_numbers": "Phone Numbers",
        "start_date": "Effective Start Date",
        "transportation": "Transportation"
      },
      "new_student": "New Student",
      "not_found": "Student not found!",
      "reenroll": {
        "action": {
          "failure": "Failed to re-enroll %{count} students",
          "success": "Re-enrolled selected students"
        },
        "btn": {
          "aide": "Re-enroll Aide",
          "student": "Re-enroll Student"
        },
        "processing": {
          "subtitle": "This may take a while...",
          "title": "Re-enrolling selected students."
        },
        "selected": "Re-enroll Selected"
      },
      "unenroll": {
        "action": {
          "failure": "Failed to unenroll %{count} students",
          "success": "Unenrolled selected students"
        },
        "btn": {
          "aide": "Unenroll Aide",
          "student": "Unenroll Student"
        },
        "form_title": "Unenroll - %{name}",
        "processing": {
          "subtitle": "This may take a while...",
          "title": "Unenrolling selected students."
        },
        "request": "Request Unenroll",
        "selected": "Unenroll Selected"
      },
      "unenrolled": "Unenrolled"
    },
    "trip": {
      "actions": {
        "refetch_gps": "Refetch GPS data",
        "simulate_tracking": "Tracking Simulation"
      },
      "assign_transport": {
        "booking": "No iCabbi booking ID assigned. Click to assign an iCabbi booking ID",
        "driver": "No Driver assigned. Click to assign a driver",
        "vehicle": "No Vehicle assigned. Click to assign a vehicle"
      },
      "avl_columns": {
        "first_pickup": "First Pickup Planned",
        "first_pickup_eta": "First Pickup ETA° / Actual",
        "last_dropoff": "Last Drop Planned",
        "last_dropoff_eta": "Last Drop ETA° / Actual",
        "mode": "Mode",
        "route": "Route",
        "tracking_link": "Tracking Link",
        "vehicle": "Vehicle",
        "vendor": "Vendor"
      },
      "avl_filters": {
        "all_schools": "All Schools"
      },
      "avl_labels": {
        "only_active": "Only active",
        "riders_type": "All Trip Types",
        "search": "Search by route, address or student",
        "show_all_schools": "Show all schools",
        "sort_by": "Sort by",
        "status": "Any Status",
        "time_zone": "All Time Zones",
        "trip_type": "Direction",
        "vehicle_type": "All Vehicle Types",
        "vendor": "All Vendors"
      },
      "avl_notification": {
        "arrived_at_school": "Route: %{route}, Vehicle: %{vehicle} has arrived",
        "late_ride": "Route: %{route}, Vehicle: %{vehicle} is running %{eta_diff} minutes late",
        "no_vehicle": "Route: %{route}, Vehicle is running late, ETA tbd"
      },
      "avl_sorting": {
        "dropoff_time": "Last Drop-off",
        "pickup_time": "First Pickup",
        "route_name": "Route name"
      },
      "counters": {
        "alert": "Missing Data",
        "all_late": "All Late (10+ minutes past planned time)",
        "arrived": "Route Completed",
        "not_started": "Not Started",
        "on_time": "0 to 9 minutes past planned time"
      },
      "error": {
        "simulate_tracking": "Can't be performed in production"
      },
      "form": {
        "booking_id": "iCabbi Booking Id",
        "note": "Note"
      },
      "icabbi": {
        "bookings": {
          "instructions": {
            "eye_to_eye": "Eye-to-eye student(s)",
            "eye_to_eye_students": "Students needing eye-to-eye drop off: %{students}"
          }
        },
        "error_mailer": {
          "banned": {
            "student": "Student %{name} has been banned in iCabbi and it's preventing bookings from being added or modified.",
            "subject": "Student account has been barred in iCabbi"
          }
        }
      },
      "links": {
        "assign_tracking_device": "Assign Tracking Device",
        "assign_vehicle": "Assign a Vehicle",
        "assign_vendor": "Assign a Vendor"
      },
      "simulate_tracking": {
        "submit": "Start",
        "title": "Tracking simulation"
      },
      "statuses": {
        "alert": "Tracking device/booking assigned. No incoming tracking data",
        "arrived": "Route Completed",
        "cancelled": "Cancelled",
        "eta_time": "%{symbol}%{minutes}min",
        "late": "10 - 19 minutes past planned time",
        "no_booking": "No booking id assigned",
        "no_data": "Tracking has not started",
        "no_device": "No tracking device assigned to this vehicle",
        "no_show": "No Show",
        "no_vehicle": "No vehicle assigned",
        "no_vendor": "No vendor assigned",
        "on_time": "0 - 9 minutes past planned time",
        "time": "%{minutes}min",
        "very_late": "20+ minutes past planned time"
      },
      "success": {
        "refetch": "Trip history will be refetched shortly",
        "simulate_tracking": "Tracking simulation started",
        "update": "Trip updated successfully"
      },
      "title": {
        "edit": "Change Run Vehicle"
      },
      "tooltips": {
        "pairing": "This route is paired with %{pair}",
        "pairing_with_adjustment": "This route is paired and has a time adjustment (early release, or late start). Please, also verify the pair: %{pair}"
      },
      "vendor_mailer": {
        "new_vendor": {
          "subject": "New vendor added",
          "vendor": "%{vendor_name} (%{provider}) was automatically added to the system."
        }
      }
    },
    "ui": {
      "address": {
        "choose": "Choose an Existing Address",
        "new": "Create a New Address",
        "school": "Use School Address",
        "student": "Choose Student Address"
      },
      "adjusted_schedule": {
        "table": "Adjusted Schedule",
        "tooltip": {
          "table": "Times for this day has been adjusted"
        }
      },
      "advanced_search": {
        "button": "Advanced Search",
        "subtitle": "Search across all districts & schools; search for users, routes, schools & vendors",
        "tabs": {
          "all": "All Results",
          "routes": "Routes",
          "schools": "Schools",
          "students": "Students",
          "users": "Users",
          "vendors": "Vendors"
        },
        "title": "Advanced Search",
        "total": "%{rows} of %{total} in %{type}"
      },
      "avl": {
        "hide_completed_stops": "Hide completed stops",
        "labels": {
          "coords": "Position",
          "school": "School",
          "stop": "Stop",
          "time": "Time",
          "vehicle": "Vehicle Name"
        },
        "show_completed_stops": "View completed stops",
        "show_waypoints": "Show waypoints",
        "title": "Tracking"
      },
      "btn": {
        "add": "Add",
        "add_slack_integration": "Add Slack Integration",
        "add_student": "Add Student",
        "archive": "Archive",
        "cancel": "Cancel",
        "create": "Create",
        "delete": "Delete",
        "edit": "Edit",
        "new_request": "New Request",
        "next": "Next",
        "print": "Print",
        "refetch_devices": "Refetch Tracking Devices",
        "reset_password": "Reset Password",
        "save": "Save",
        "save_and_close": "Save & Close",
        "unarchive": "Unarchive",
        "update": "Update",
        "update_slack_token": "Update Slack Token",
        "view": "View",
        "view_edit": "View/Edit"
      },
      "comms": {
        "title": "Comms"
      },
      "confirm_password_reset": "An e-mail will be sent to the user with instructions for resetting the password.",
      "curbside": {
        "directions": {
          "custom": "Non-school trips",
          "from_school": "From School",
          "to_school": "To School"
        },
        "filter": {
          "all": "All",
          "all_other": "Other (including Field Trips)",
          "changes_only": "Changes Only",
          "regular_only": "Primary Only",
          "show_all_schools": "Show All Schools"
        },
        "select_school": "Select a School or District"
      },
      "day_off": {
        "calendar": "NO SCHOOL",
        "date": "Day Off",
        "table": "No School",
        "tooltip": {
          "table": "No school on this day"
        }
      },
      "empty_field": "Empty",
      "env_banner": "You are logged into the %{env} Environment",
      "errors": {
        "not_allowed": "Not allowed",
        "report": "Report generation failed.",
        "taken": "Already been taken"
      },
      "export": {
        "date_range_to": "TO"
      },
      "footer": {
        "privacy": "Privacy Policy"
      },
      "form": {
        "directions": {
          "empty": "Select Direction"
        },
        "district": {
          "empty": "Select a District",
          "label": "District"
        },
        "file": {
          "empty": "Choose File",
          "label": "Data File:",
          "no_file": "No file selected"
        },
        "grade": {
          "empty": "All Grades",
          "label": "Grade:"
        },
        "miss_stop": {
          "label": "With Missed Stop"
        },
        "operator": {
          "empty": "Select an Operator"
        },
        "period": {
          "label": "Apply changes to:"
        },
        "program": {
          "empty": "All Programs",
          "label": "Program:"
        },
        "report_format": {
          "label": "Report Formats:"
        },
        "report_type": {
          "empty": "Select a Report Type",
          "label": "Report:"
        },
        "riders_type": {
          "empty": "All passengers",
          "label": "Passengers Type:"
        },
        "route_name": {
          "label": "Route"
        },
        "school": {
          "empty": "Select a School",
          "label": "School"
        },
        "tier_one_route_id": {
          "empty": "Select Tier 1 Route"
        },
        "tier_two_route_id": {
          "empty": "Select Tier 2 Route"
        },
        "time_zone": {
          "empty": "Select a Timezone",
          "label": "Timezone"
        },
        "trip_delay": {
          "empty": "Trip Delay",
          "label": "Trip Delay"
        },
        "vehicle": {
          "label": "Select Vehicle"
        },
        "vehicle_type": {
          "empty": "Vehicle Type",
          "label": "Vehicle Type"
        },
        "vendor": {
          "empty": "All Vendors",
          "label": "Transportation Vendor:"
        },
        "vendor_required": {
          "empty": "Select Vendor",
          "label": "Transportation Vendor:"
        }
      },
      "items": {
        "one": "1 item",
        "other": "%{count} items",
        "zero": "0 items"
      },
      "main": {
        "title": "4Mativ"
      },
      "mode_editor": {
        "add": "Add to Stop / Route",
        "add_note": "Add a note:",
        "add_trip": "Extra Trip",
        "back": "back",
        "change": "Change Stop / Route",
        "change_mode": "Type of Change",
        "filter": {
          "school": {
            "empty": "Any School",
            "label": "Filter by School"
          },
          "student": "Student",
          "student_placeholder": "Find a student"
        },
        "form": {
          "change_mode": {
            "label": "Choose mode to switch to"
          },
          "existing_stop": {
            "dropoff": "Choose New Arrival Address (Existing Stops / Routes)",
            "pickup": "Choose New Departing Address (Existing Stops / Routes)",
            "placeholder": "Search or choose an existing routes/stops"
          }
        },
        "message": {
          "date_in_past": "Effective date can't be earlier than now in school timezone (%{date})",
          "fail": "Changes for %{name} failed",
          "fail_fetch": "Failed to fetch the data",
          "fail_trip": "Changes for the trip failed",
          "new_stop": "Adding a new stop address requires administrative approval. Submit your request.",
          "success": "Changes for %{name} saved"
        },
        "mode": {
          "change_mode": "Change mode (i.e. PPU, walk)",
          "change_to_existing_oneoff_stop": "Change to an existing one-off route stop",
          "change_to_existing_stop": "Change to an existing stop",
          "choose": "Choose...",
          "create_new_oneoff_stop": "Create a stop on an existing one-off route",
          "create_new_route": "Create a new route",
          "create_new_stop_on_route": "Create a new stop on an existing route",
          "create_one_off_route": "Create a one-off route",
          "request_new_stop": "Request a new stop"
        },
        "new_request": "New Request",
        "state": {
          "by_route": "Search by route",
          "by_stop": "Search by stop / address",
          "create": "Create New Route",
          "new_stop": "Request New Stop"
        }
      },
      "negative": "No",
      "paired_warning": {
        "tooltip": "Potential risk to the pairing"
      },
      "positive": "Yes",
      "queues": {
        "filter": {
          "date_from": "From",
          "date_range": "Date Range",
          "date_to": "To",
          "requestor": "All Requestors",
          "school": "All Schools",
          "status": "Any Status",
          "student": "Student"
        }
      },
      "report": {
        "form": {
          "btn": {
            "create": "Create Report",
            "preview": "Preview Report",
            "save_pdf": "Save as pdf",
            "view": "View Reports"
          },
          "label": {
            "date": "Date:",
            "school": "Schools:"
          }
        },
        "preview": {
          "avery": "Dismissal Labels - Avery 95945 Preview",
          "emergency": "If Found Call"
        },
        "status": {
          "download": "download it",
          "generating": {
            "multiple": "Your reports are being generated. We'll contact you when it's ready. ",
            "single": "Your report is being generated. We'll contact you when it's ready. "
          },
          "ready": "Your report is ready, ",
          "route_sheets": {
            "email": "Your report is ready, you will receive an email shortly"
          },
          "view": "view all reports"
        }
      },
      "required_note": "* Indicates a required field",
      "route": {
        "actions": {
          "created": "New Route Created."
        },
        "add": "Add a New Route",
        "archived": "Archived",
        "choose": "Choose an Existing Route",
        "choose_existing_oneoff": "Choose from an existing One-Off Route",
        "comment": {
          "label": "Comment"
        },
        "create_another": "Create another route",
        "create_oneoff": "Create new One-off Route",
        "dates": {
          "label": "Date(s)"
        },
        "details": {
          "create_return": "Create Return"
        },
        "inbound_driver": {
          "empty": "Inbound Driver",
          "label": "Inbound Driver"
        },
        "inbound_vehicle": {
          "empty": "Inbound Vehicle",
          "label": "Inbound Vehicle"
        },
        "looping": {
          "label": "Is a Looping Route (constantly repeating stops)"
        },
        "name": {
          "label": "Route Name",
          "placeholder": "Name your new route"
        },
        "new": "Create a New Route",
        "outbound_driver": {
          "empty": "Outbound Driver",
          "label": "Outbound Driver"
        },
        "outbound_vehicle": {
          "empty": "Outbound Vehicle",
          "label": "Outbound Vehicle"
        },
        "period": {
          "repeat": "Repeat Every",
          "repeat_range": "Week(s)"
        },
        "push_to_avl": "Push update to Vendor now",
        "return": "Add a return",
        "riders_type": {
          "delivery": "Delivery",
          "students": "Student Route"
        },
        "routable_type": {
          "label": {
            "district": "District",
            "school": "School"
          },
          "select": {
            "district": "Cross-School",
            "school": "Default"
          },
          "short": {
            "district": "XS",
            "school": "-"
          }
        },
        "route_type": {
          "activity": "Activity",
          "custom": "Other",
          "label": "Route Type",
          "regular": "Primary"
        },
        "trip_type": {
          "from_custom": "Outbound",
          "to_custom": "Inbound"
        },
        "vehicle_type": {
          "empty": "Vehicle Type",
          "label": "Vehicle Type"
        },
        "vendor": {
          "empty": "Vendor",
          "label": "Transportation Vendor"
        }
      },
      "schedule": {
        "details": {
          "hide": "Hide details",
          "show": "See details"
        },
        "route": {
          "info": "This route runs %{details}",
          "not_run": "This route runs %{details}, NOT including this week",
          "run": "This route runs %{details}, including this week"
        }
      },
      "sorting": "Sort by %{label}",
      "stop": {
        "anchor": "Anchor",
        "choose": "Choose an Existing Stop",
        "create": {
          "dropoff": "New Arrival Address",
          "pickup": "New Departing Address"
        },
        "dropoff": "Arrival Address",
        "end_date": "End Date",
        "label": {
          "dropoff": "Drop Off",
          "pickup": "Pick Up"
        },
        "new": "Create a New Stop",
        "non_riding": {
          "empty": "Choose mode"
        },
        "period": {
          "activity": "Multiple Days",
          "custom": "Multiple Days",
          "regular": "%{date}, until the route's end date",
          "school": "%{date}, until the school's end date"
        },
        "pickup": "Departing Address",
        "requested": {
          "dropoff": "Requested Drop Off",
          "pickup": "Requested Pick Up"
        },
        "same": {
          "dropoff": "Same Drop Off",
          "pickup": "Same Pick Up"
        },
        "school": {
          "dropoff": "School Drop Off Address",
          "empty": "Choose School Address",
          "pickup": "School Pick Up Address"
        },
        "school_time": {
          "empty": "Choose School Time"
        },
        "start_date": "Start Date",
        "student": {
          "empty": "Choose Student Address"
        },
        "time": {
          "arrival_time": "Arrival Time",
          "departure_time": "Departure Time (Arrival + Wait)",
          "dropoff": "Drop Off Time",
          "pickup": "Pick Up Time",
          "school": "Select from School Times",
          "time_zone": "(Time Zone: %{tz})",
          "wait_time": "Wait Time (minutes)"
        }
      },
      "stop_location": {
        "add": "Add an Existing Stop Location",
        "choose": "Choose an Existing Stop Location",
        "new": "Create a New Stop Location"
      },
      "student": {
        "count": {
          "one": "1 Student",
          "other": "%{count} Students",
          "zero": "0 Students"
        },
        "dropoff": "-%{count} Students (Dropoff)",
        "pickup": "+%{count} Students (Pickup)",
        "weekly": {
          "add_trip": "Add Trip",
          "add_trip_header": "Add a New Trip - %{name}",
          "hide_trip": {
            "one": "Hide 1 Extra Trip",
            "other": "Hide %{count} Extra Trips"
          },
          "no_trips": "No Extra Trips",
          "show_trip": {
            "one": "Show 1 Extra Trip",
            "other": "Show %{count} Extra Trips"
          }
        }
      },
      "students": {
        "dropoff": "-%{students} (Dropoff)",
        "pickup": "+%{students} (Pickup)"
      },
      "trip": {
        "invalid_vehicle_type": "Vehicle selected does not match the type assigned to this route: %{vehicle_type}. To keep this selection, update the Route Vehicle Type",
        "last_update": "Last Updated: %{updated}",
        "missed_stop": "Missed Stop",
        "next_stop": "Next Stop",
        "no_trips": "No Trips Found",
        "select_school": "Select a School or District",
        "students": "Students"
      },
      "vendor_schedule": {
        "add": "Assign a Vendor / Vehicle",
        "last_updated": "Last updated: %{date} %{tz} by %{user}"
      }
    },
    "vendor_config": {
      "device": {
        "labels": {
          "device_id": "Device Id",
          "identifier": "Identifier",
          "name": "Name",
          "provider": "Provider",
          "vendor": "Vendor"
        },
        "title": {
          "details": "Device Details"
        }
      },
      "devices": {
        "breadcrumb": "All Devices",
        "form": {
          "name": {
            "label": "Name"
          },
          "provider": {
            "label": "Provider"
          },
          "save": "Save Device",
          "submit": "Create Device",
          "vendor": {
            "empty": "Select a Transportation Vendor",
            "label": "Transportation Vendor"
          }
        }
      },
      "driver": {
        "labels": {
          "created_at": "Created at",
          "email": "Email",
          "first_name": "First Name",
          "last_name": "Last Name",
          "license_id": "License",
          "updated_at": "Updated at",
          "vendor": "Vendor"
        },
        "title": {
          "details": "Driver Details",
          "phone_numbers": "Phone Numbers"
        }
      },
      "drivers": {
        "breadcrumb": "All Drivers",
        "form": {
          "email": {
            "label": "Email"
          },
          "first_name": {
            "label": "First Name"
          },
          "last_name": {
            "label": "Last Name"
          },
          "license_id": {
            "label": "License"
          },
          "save": "Save Driver",
          "submit": "Create Driver",
          "vendor": {
            "empty": "Select a Transportation Vendor",
            "label": "Transportation Vendor"
          }
        }
      },
      "edit_device": {
        "btn": "Edit Device",
        "title": "Edit Device"
      },
      "edit_driver": {
        "btn": "Edit Driver",
        "title": "Edit Driver"
      },
      "edit_staff": {
        "btn": "Edit Vendor Staff",
        "title": "Edit Vendor Staff"
      },
      "edit_vehicle": {
        "btn": "Edit Vehicle",
        "title": "Edit Vehicle"
      },
      "edit_vendor": {
        "btn": "Edit Vendor",
        "title": "Edit Vendor"
      },
      "new_device": {
        "btn": "New Device",
        "title": "New Device"
      },
      "new_driver": {
        "btn": "New Driver",
        "title": "New Driver"
      },
      "new_staff": {
        "btn": "New Vendor Staff",
        "title": "New Vendor Staff"
      },
      "new_vehicle": {
        "btn": "New Vehicle",
        "title": "New Vehicle"
      },
      "new_vendor": {
        "btn": "New Vendor",
        "title": "New Vendor"
      },
      "staff": {
        "labels": {
          "communication_access": "Comms Access",
          "created_at": "Created at",
          "email": "Email",
          "first_name": "First Name",
          "last_name": "Last Name",
          "phone_number": "Phone",
          "role": "Role",
          "staff_role": "Staff Role",
          "system_user": "Full TOMS Access",
          "updated_at": "Updated at",
          "vendor": "Vendor"
        },
        "title": {
          "communication_groups": "Communication Groups",
          "details": "Staff Member Details",
          "phone_numbers": "Phone Numbers"
        },
        "update": "Update Staff Member"
      },
      "staffs": {
        "breadcrumb": "All Vendor Staff",
        "form": {
          "email": {
            "empty": "Email",
            "label": "Email"
          },
          "first_name": {
            "empty": "First Name",
            "label": "First Name"
          },
          "last_name": {
            "empty": "Last Name",
            "label": "Last Name"
          },
          "save": "Save Vendor Staff",
          "staff_role": {
            "empty": "Staff Role",
            "label": "Staff Role"
          },
          "submit": "Create Vendor Staff",
          "system_user": {
            "label": "Full TOMS Access"
          },
          "vendor": {
            "empty": "Select a Vendor",
            "label": "Vendor"
          }
        }
      },
      "title": "Vendor Configuration",
      "vehicle": {
        "labels": {
          "capacity": "Capacity",
          "created_at": "Created at",
          "external_id": "External Id",
          "icabbi_id": "Icabbi Id",
          "license_plate": "License Plate",
          "name": "Name",
          "tracking_device": "Tracking Device",
          "updated_at": "Updated at",
          "vehicle_type": "Type",
          "vendor": "Vendor"
        },
        "title": {
          "details": "Vehicle Details"
        }
      },
      "vehicles": {
        "breadcrumb": "All Vehicles",
        "form": {
          "capacity": {
            "label": "Capacity"
          },
          "external_id": {
            "label": "External Id"
          },
          "license_plate": {
            "label": "License Plate"
          },
          "name": {
            "label": "Name"
          },
          "save": "Save Vehicle",
          "submit": "Create Vehicle",
          "tracking_device_id": {
            "empty": "Select a Tracking Device",
            "label": "Tracking Device"
          },
          "vehicle_type": {
            "empty": "Select a Vehicle Type",
            "label": "Vehicle Type"
          },
          "vendor": {
            "empty": "Select a Transportation Vendor",
            "label": "Transportation Vendor"
          }
        }
      },
      "vendor": {
        "labels": {
          "created_at": "Created at",
          "email": "Email",
          "name": "Name",
          "provider": "Provider",
          "updated_at": "Updated at"
        },
        "title": {
          "addresses": "Addresses",
          "details": "Vendor Details",
          "phone_numbers": "Phone Numbers"
        }
      },
      "vendors": {
        "breadcrumb": "All Vendors",
        "form": {
          "api_key": {
            "label": "API Key"
          },
          "api_url": {
            "label": "API Url"
          },
          "customer": {
            "label": "Customer/Fleet ID"
          },
          "email": {
            "label": "Email"
          },
          "name": {
            "label": "Name"
          },
          "password": {
            "label": "Password"
          },
          "provider": {
            "label": "Provider"
          },
          "username": {
            "label": "Username"
          }
        }
      }
    },
    "vendors": {
      "no_data": "No data matching selected table filters. Adjust table filters for more results.",
      "no_schedule": "Data not available yet",
      "trips": {
        "directions": {
          "from_custom": "Other",
          "from_school": "From School",
          "to_custom": "Other",
          "to_school": "To School"
        },
        "filters": {
          "all_directions": "All Directions",
          "all_runs": "All Runs",
          "all_schools": "All Schools",
          "planned_only": "Planned Runs Only"
        },
        "labels": {
          "accommodations": "Accommodations",
          "dropoff_time": "Last Drop Planned",
          "note": "Notes",
          "pickup_time": "First Pickup Planned",
          "route_name": "Run Name",
          "school": "School",
          "trip_type": "Direction",
          "vehicle": "Vehicle",
          "vehicle_type": "Vehicle Type"
        },
        "title": "Routes"
      }
    }
  }
}